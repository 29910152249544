import React, {useState} from 'react';
import {
    AlertColor,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import CloseIcon from "@mui/icons-material/Close";
import {APIPut} from "../../../FetchBuilder";
import {SnackInformation} from "../../../utils/snackInformation";

const {REACT_APP_API_URL} = process.env;

function ChangeEmailDialog(props: any) {

    const {open, onClose, t, user} = props

    const handleChangeEmail = (event: any) => {
        setNewEmail(event.currentTarget.value);
    }

    const [newEmail, setNewEmail] = useState('');
    const [severity, setSevirity] = React.useState<AlertColor>("success");
    const [openAlert, setOpenAlert] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");

    const update = () => {
        APIPut<any>(REACT_APP_API_URL + "/admin/user/" +user.uuid+"/email", {
            email: newEmail,
        }).then((data) => {
            if (data.status >= 400) {
                setSevirity("error");
                setMessage(t("update_error_user"));
                setOpenAlert(true);
            } else if (data.parsedBody !== undefined) {
                setSevirity("success");
                setMessage(t("update_success_user"));
                setOpenAlert(true);
            }
        }, (error) => {
            console.log(error);
            setSevirity("error");
            setMessage(t("update_error_user"));
            setOpenAlert(true);
        });
    }

    return (
        <>
            <Dialog
                key={'confirmdelete'}
                fullWidth
                maxWidth={'sm'}
                open={open}
                keepMounted
                onClose={onClose}
            >
                <DialogTitle style={{padding: '24px'}}>
                    <Box display="flex" alignItems="center">
                        <Box style={{textAlign: 'left'}} flexGrow={1}>{t("Modifier email client")}</Box>
                        <IconButton onClick={onClose}><CloseIcon/></IconButton>
                    </Box>
                </DialogTitle>

                <DialogContent style={{padding: '24px'}}>
                    <Grid container justifyContent={'center'} alignItems="center">
                        <Grid item xs={12} md={10} lg={8}>
                            <TextField
                                label={'nouvel email'}
                                value={newEmail}
                                margin={'dense'}
                                variant={'outlined'}
                                type={'text'}
                                onChange={handleChangeEmail}
                                style={{width: '100%'}}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions style={{padding: '24px'}}>
                    <Grid>
                        <Button color={'secondary'} onClick={onClose}>{t("Cancel")}</Button>
                        <Button color={'primary'} onClick={update}>{t("Modifer email")}</Button>
                    </Grid>
                </DialogActions>
                <SnackInformation open={openAlert} severity={severity} message={message} close={() => {setOpenAlert(false);}}/>
            </Dialog>
        </>
    )
}

export function SearchUserByEmail(props: any) {
    const {t} = useTranslation(['home']);

    const [email, setEmail] = useState<string>('');
    const [open, setOpen] = useState(false);

    //! METHODS

    const handleChangeEmail = (event: any) => {
        setEmail(event.currentTarget.value);
    }

    const handleSearchClick = () => {
        props.displayInfos(email);
    }

    //! DISPLAY

    const display = () => {
        return (
            <>
            <Grid container spacing={1} alignItems='center' justifyContent='center' sx={{marginTop: '25px', mr: 2}}>
                <Grid item xs={4}>
                    <TextField
                        label={'email'}
                        value={email}
                        margin={'dense'}
                        variant={'outlined'}
                        type={'text'}
                        onChange={handleChangeEmail}
                        style={{width: '100%'}}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Button
                        variant={'contained'}
                        onClick={handleSearchClick}
                    >
                        {t('Search')}
                    </Button>
                </Grid>
                {
                    props.user !== undefined && props.user !== null ?
                        <Grid item xs={2}>
                            <Button
                                variant={'contained'}
                                onClick={() => setOpen(true)}
                            >
                                {t('Modifier email')}
                            </Button>
                        </Grid>
                        :null
                }
            </Grid>
        <ChangeEmailDialog
            user={props.user}
            open={open}
            onClose={() => setOpen(false)}
            {...{t}}
        />
        </>
        )
    }

    return display();
}
